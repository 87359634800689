import Vue from 'vue';

import getters from './getters';

import mutations from './mutations';

import actions from './actions';

import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

	actions,

	getters,

	mutations,

	state: {

		types: [],

		locations: [],

		beds: [],

		price_range: '',

		type_selected: '', 

		bed_selected: [], 

		location_selected: '',

		sub_location_selected: '',

		price_selected: '',

		property_name: '',

		projectResults: [],

		projectsDisplay: [],

		projects: [],

		recommended_projects:[],

		something_you_may_like:[],

		size_selected: '',

	},

});