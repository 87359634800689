export default {

	updateProjects(state, projects) {

		state.projects = projects;

	},

	updateType(state, type) {

		state.type_selected = type;

        state.location_selected = '';

		state.size_selected = '';

        localStorage.setItem("type_selected", type);

	},

	updateLocation(state, location) {

		state.location_selected = location;

		state.size_selected = '';

        localStorage.setItem("location_selected", location);

	},

	updateSize(state, size) {

		state.size_selected = size;

        localStorage.setItem("size_selected", size);

	},

}