export default {

	typeProjectLocation: state => {

		if (state.type_selected == '')  {

			return state.projects;

		}else {
			
			return state.projects.filter(project => {

				return project.details.office_type == state.type_selected;

			});
		
		}

	},

	typeProjectLocationSize: state => {

		if (state.type_selected == '')  {

			return state.projects;

		}else if(state.type_selected != '' && state.location_selected != ''){

			return state.projects.filter(project => {

				return project.details.office_type == state.type_selected && project.locations[1].title == state.location_selected;

			});

		}else{
			
			return state.projects.filter(project => {

				return project.details.office_type == state.type_selected;

			});
		
		}

	},

	filteredProjects: state => {

		if (state.type_selected == '' && state.location_selected == '')  {

			return state.projects;

		}else if (state.type_selected != '' && state.location_selected != '' && state.size_selected != '') {

			if (state.type_selected == 'for_lease') {

				let size;

				switch(state.size_selected) {
				
					case 'Below 1,000 sqm':
							
						return state.projects.filter(project => {

							return project.details.office_type == state.type_selected && project.locations[1].title == state.location_selected && project.details.lease_min_size != null && project.details.lease_min_size <= 1000;

						});

					break;
					
					case '1,000 sqm - 2,000 sqm':
						
						return state.projects.filter(project => {

							return project.details.office_type == state.type_selected && project.locations[1].title == state.location_selected && project.details.lease_min_size != null && project.details.lease_min_size <= 2000;

						});

					break;
					
					default:

						return state.projects.filter(project => {

							return project.details.office_type == state.type_selected && project.locations[1].title == state.location_selected && project.details.lease_min_size != null && project.details.lease_min_size > 2000;

						});
				
				}

			}else{

				let range = state.size_selected.split(' - ');

				return state.projects.filter(project => {

					return project.details.office_type == state.type_selected && project.locations[1].title == state.location_selected && project.details.sale_min_size == range[0] && project.details.sale_max_size == range[1];

				});

			}

		}else if (state.type_selected != '' && state.size_selected != '') {

			if (state.type_selected == 'for_lease') {

				let size;

				switch(state.size_selected) {
				
					case 'Below 1,000 sqm':
							
						return state.projects.filter(project => {

							return project.details.office_type == state.type_selected && project.details.lease_min_size != null && project.details.lease_min_size <= 1000;

						});

					break;
					
					case '1,000 sqm - 2,000 sqm':
						
						return state.projects.filter(project => {

							return project.details.office_type == state.type_selected && project.details.lease_min_size != null && project.details.lease_min_size <= 2000;

						});

					break;
					
					default:

						return state.projects.filter(project => {

							return project.details.office_type == state.type_selected && project.details.lease_min_size != null && project.details.lease_min_size > 2000;

						});
				
				}

			}else{

				let range = state.size_selected.split(' - ');

				return state.projects.filter(project => {

					return project.details.office_type == state.type_selected && project.details.sale_min_size == range[0] && project.details.sale_max_size == range[1];

				});

			}

		}else if (state.type_selected != '' && state.location_selected != '') {

			return state.projects.filter(project => {

				return project.details.office_type == state.type_selected && project.locations[1].title == state.location_selected;

			});

		}else if (state.type_selected != '') {

			return state.projects.filter(project => {

				return project.details.office_type == state.type_selected;

			});

		}else{

			return state.projects.filter(project => {

				return project.locations[1].title == state.location_selected;

			});

		}

	},

	locations: (state, getters) => {

		let locations = [];

		getters.typeProjectLocation.forEach(project => {

			let title = project.locations[1].title;

			if (! locations.includes(title)) {

				locations.push(title)
			
			}

		});

		return locations.sort();

	},

	sizes: (state, getters) => {

		let sizes = [];

		if (state.type_selected == 'for_lease') {

			let dummy_sizes = [];
			
			getters.typeProjectLocationSize.forEach(project => {

				if (project.details.lease_min_size != null) {

					let size_text;

					if (project.details.lease_min_size > 2000) {

						size_text = '2,000 sqm and up';

					}else if(project.details.lease_min_size > 1000){

						size_text = '1,000 sqm - 2,000 sqm';
					
					}else{

						size_text = 'Below 1,000 sqm';

					}

					if (! dummy_sizes.includes(size_text)) {

						dummy_sizes.push(size_text)
					
					}

				}

			});


			let size_options = ['Below 1,000 sqm', '1,000 sqm - 2,000 sqm', '2,000 sqm and up'];

			size_options.forEach(element => {
			
				dummy_sizes.forEach(element1 => { if (element1 == element) { sizes.push(element);} });

			});

		}else{

			getters.typeProjectLocationSize.forEach(project => {

				if (project.details.sale_min_size != null) {

					let size_text = project.details.sale_min_size + ' - ' + project.details.sale_max_size;

					if (! sizes.includes(size_text)) {

						sizes.push(size_text)
					
					}


				}

			});

		}

		return sizes;

	},

}